import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import ContentContainer from "../components/Core/Containers/ContentContainer"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"

const TextPageContainer = styled.div`
  margin-top: 40px;
  margin: 40px 0 140px;

  @media (min-width: ${breakpoints.sm}) {
    padding: 0 30px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin: 200px 0 120px;
  }
`

const Content = styled.div`
  color: ${colors.charcoal};
  max-width: 720px;
  margin: auto;
  h1 {
    ${fonts.garamondRegular};
    text-align: center;
    font-size: 40px;
    letter-spacing: -0.3px;
    line-height: 1.1;
    margin-bottom: 50px;
  }

  h2 {
    ${fonts.garamondRegular};
    font-size: 28px;
    margin: 10px 0 20px;
    line-height: 1.2;
  }

  h3 {
    ${fonts.garamondSemiBold};
    font-size: 20px;
    line-height: 1.2;
    margin: 10px 0;
  }

  p {
    ${fonts.sofiaPro};
    font-size: 12px;
    line-height: 1.38;
    margin-bottom: 20px;
    :last-of-type {
      margin-bottom: 0;
    }
  }
  a {
    color: inherit;
  }
  b {
    font-weight: 700;
  }
  i {
    font-style: italic;
  }

  ul {
    padding-left: 40px;
    margin: 20px 0;
    li {
      list-style: disc;
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 1.38;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    h1 {
      font-size: 46px;
    }

    h2 {
      font-size: 32px;
      margin: 10px 0;
    }
  }
  @media (min-width: ${breakpoints.lg}) {
    h1 {
      font-size: 60px;
    }
  }
`

const CookieButton = styled.button`
  ${fonts.sofiaPro};
  font-size: 12px !important;
  line-height: 1.33 !important;
  text-decoration: underline;
  background: transparent;
  padding: 0 !important;
  border: 0 !important;
  outline: none;
  cursor: pointer;

  :hover {
    background: transparent !important;
  }
`

const TextPageTemplate = (props) => {
  const path = props.location.pathname
  const { page } = props.data
  const { metaTitle, metaDescription, socialMediaImage, content } = page

  let sharingImage = false

  if (socialMediaImage && socialMediaImage.file) {
    sharingImage = socialMediaImage.file.url
  }

  const handleCookieClick = () => {
    if (typeof window !== "undefined" && typeof window.OneTrust !== "undefined") {
      window.OneTrust.ToggleInfoDisplay()
    }
  }

  const options = {
    renderText: text => {
      let newText = text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
  
      if (newText[1].includes("%cookies%")) {
        newText = newText.map((content, index) => {
          if (index > 0) {
            const contentSections = content.split("%cookies%")
            return (
              <>
                {contentSections[0]}
                <CookieButton onClick={() => handleCookieClick()}>Cookie Preferences</CookieButton>
                {contentSections[1]}
              </>
            )
          }
          return content
        })
      }
      return newText
    },
  };

  return (
    <Layout transparentHeader={true}>
      <Seo
        path={path}
        title={metaTitle}
        description={metaDescription}
        fullTitle={false}
        image={sharingImage}
      />
      <TextPageContainer>
        <ContentContainer>
          <Content>{renderRichText(content, options)}</Content>
        </ContentContainer>
      </TextPageContainer>
    </Layout>
  )
}

export default TextPageTemplate

export const pageQuery = graphql`
  query TextPageTemplateQuery($slug: String!) {
    page: contentfulTextPage(slug: { eq: $slug }) {
      id
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
      content {
        raw
      }
    }
  }
`
